import React from 'react'

function WrapperCard(props) {
  return (
    <div className={` xl:w-[1200px] lg:w-[1020px] md:w-[750px] sm:w-[550px] px-4 mx-auto py-6 ${props.className}`}>
        {props.children}
    </div>
  )
}

export default WrapperCard