import React from 'react'
import { Link } from 'react-router-dom';
import Heading from '../../../components/UI/Heading';
import SubHead from '../../../components/UI/SubHead';
import WrapperCard from '../../../components/UI/WrapperCard'

function TopHospitals(props) {

    const hospitals = props.allHospitals;
    const skeleton = [1,2,3,4,5,6]
    return (
        <WrapperCard className='relative text-center'>
            <Heading heading='Top Hospitals'/>
            <SubHead subhead='Explore Hospitals For Your Treatment'/>
            {   hospitals.length === 0 &&
                <div className=' mb-6 justify-center mt-8 grid gap-6 md:grid-cols-2 xl:grid-cols-3'>
                {skeleton.map((key) => (
                    <span 
                        className='p-1 w-full flex gap-6 items-center justify-between'
                        key={key}
                    >
                        <div className='skeleton w-28 h-28 shrink-0 basis-28'></div>
                        <div className='flex flex-col gap-2 w-40 self-center basis-full'>
                            <span className='h-2 w-40 skeleton'></span>
                            <span className='h-2 w-40 skeleton'></span>
                            <span className='h-2 w-40 skeleton'></span>
                            <span className='h-2 w-28 skeleton'></span>
                        </div>
                    </span>
                ))}
            </div>}
            <div className=' mb-6 justify-center mt-8 grid gap-6 md:grid-cols-2 xl:grid-cols-3'>
                {hospitals.slice(0,6).map((hospital, id) => (
                    <Link 
                        key={id} 
                        className='p-1 hover:bg-lightbg w-full flex gap-6 items-center'
                        to={
                            {pathname:  `/${hospital.publiclink}` }
                        } 
                        state={
                            {props: hospital}
                        }
                    >
                        <img src={hospital.img} title={hospital.name} className=' skeleton w-28 h-28 border-2 shadow flex-shrink-0' alt='hosp img'/>
                        <div className=' text-left text-gray-600 flex flex-col self-center'>
                            <span className='text-sm'>{hospital.category}</span>
                            <h2 className=' text-lg font-semibold'>{hospital.name}</h2>
                            <span className='text-sm'>{hospital.address.city}, {hospital.address.state}</span>
                            <span className=' font-bold text-yellow-400'>★★★★★</span>
                        </div>
                    </Link>
                ))}
            </div>
            <Link to='/all-hospitals' className='p-2 px-4 hover:bg-indigo-900 font-semibold text-white bg-sky-700 border-white'>view more</Link>
        </WrapperCard>
    )
}

export default TopHospitals