import React from 'react'
import Heading from '../../../components/UI/Heading'
import SubHead from '../../../components/UI/SubHead'
import WrapperCard from '../../../components/UI/WrapperCard'
import { whys } from '../../../assets/constants'

function Why() {
  return (
    <WrapperCard className=' lg:gap-32 md:gap-16 flex flex-col md:flex-row gap-4'>
        <div className='md:basis-6/12'>
            <img className='shadow-lg' loading='lazy' title='best-doctors-in-india' src='/images/best-doctors-in-india.webp' alt='why-treatat'/>
        </div>
        <div className=''>
            <Heading heading="Why treatat"/>
            <SubHead subhead='4000+ patients can’t go wrong!'/>
            <br/>
            <div className='flex flex-col gap-4'>
                {
                    whys.map((why, key) => (
                        <div className='flex gap-2' key={key}>
                            <span className=' text-2xl text-green-900 font-bold'>&#1421;</span>
                            <p className='text-gray-600'>
                                <span className=' font-bold text-lg '>{why.main}</span>
                                <br/>
                                <span className=' text-sm font-semibold'>{why.sub}</span>
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
    </WrapperCard>
  )
}

export default Why