import React, { useState } from 'react'
import Heading from '../../../components/UI/Heading';
import SubHead from '../../../components/UI/SubHead';
import WrapperCard from '../../../components/UI/WrapperCard';

function Confused() {
    
    const[isDown, setDown] = useState(false);
    const[startX, setStartX] = useState(0);
    const[scrollLeft, setScrollLeft] = useState(0);

    const end = (e) => {
        setDown((prev) => false)
        e.target.classList.remove('cursor-grabbing')
        e.target.classList.add('cursor-grab')
    } 
    
    const start = (e) => {
        setDown((prev) => true)
        setStartX((prev) => (e.pageX - e.target.offsetLeft))
        setScrollLeft((prev) => e.target.scrollLeft)
        e.target.classList.add('cursor-grabbing')
        e.target.classList.remove('cursor-grab')
    }
    
    const move = (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - e.target.offsetLeft;
        const dist = (x - startX);
        e.target.scrollLeft = scrollLeft - dist;
    }

      

    return (
        <WrapperCard className=' text-center'>
            <Heading heading='Make the right choices for you treatment'/>
            <SubHead subhead="Don't just take our word for it." />
            <div 
                onMouseEnter={(e)=>{e.target.classList.add('cursor-grab')}} 
                onMouseDown={start} 
                onMouseMove={move} 
                onMouseLeave={end} 
                onMouseUp={end} 
                className='xl:justify-center hide-scroll my-6 text-white overflow-scroll flex gap-6 h-36'
            >
                <div className=' pointer-events-none bg-primarybg relative text-left shrink-0 h-full w-80 '>
                    <div className=' p-4 bottom-0 absolute'>
                        <span className=' text-3xl'>4000+</span>
                        <p className=' font-bold'>Happy Patients</p>
                    </div>
                </div>
                <div className='pointer-events-none bg-primarybg relative text-left shrink-0 h-full w-80 '>
                    <div className=' p-4 bottom-0 absolute'>
                        <span className=' text-3xl'>20+</span>
                        <p className=' font-bold'>Super Speciality Hospitals</p>
                    </div>
                </div>
                <div className=' pointer-events-none bg-primarybg relative text-left shrink-0 h-full w-80 '>
                    <div className=' p-4 bottom-0 absolute'>
                        <span className=' text-3xl'>$500000+</span>
                        <p className=' font-bold'>Saved in Treatment Costs</p>
                    </div>
                </div>
            </div>
        </WrapperCard>
    )
}

export default Confused