import React from 'react'
import { Link } from 'react-router-dom'

function ButtonAssist(props) {
  return (
    <Link className={`${props.className} hover:bg-gradient-to-r from-green-800 to-gray-600 text-lg text-white font-bold w-fit rounded-md px-10 py-4 bg-red-900`} to={props.to}>
        {props.text}
        {props.children}
    </Link>
  )
}

export default ButtonAssist