import React from 'react'
import Heading from '../../../components/UI/Heading'
import SubHead from '../../../components/UI/SubHead'
import WrapperCard from '../../../components/UI/WrapperCard'
import { hows } from '../../../assets/constants'


function How() {
  return (
    <WrapperCard className='flex flex-col gap-6 sm:text-center'>
        <div>
        <Heading heading='How does it work?'/>
        <SubHead subhead='TreatAt gets you to the right treatment in four simple steps!'/>
        </div>
        <div className='flex flex-col sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-6 text-left'>
            { hows.map((how, key) => (
                <div key={key} className='flex md:text-center sm:flex-col gap-4'>
                    <span className='p-3 self-center bg-center rounded-full bg-gradient-to-r from-green-800 to-green-500 w-fit h-fit'>
                        <how.icon className=' text-2xl md:text-4xl text-white'/>
                    </span>
                    <div className=' grid gap-2'>
                        <h2 className=' font-bold text-lg text-gray-600'>
                            {how.step}
                        </h2>
                        <p className=' text-sm text-gray-700'>
                            {how.detail}
                        </p>
                    </div>
                </div>
            ))}
           
        </div>
    </WrapperCard>
  )
}

export default How