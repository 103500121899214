import React from 'react'
import { whys } from '../../assets/constants'
import Heading from '../../components/UI/Heading'
import SubHead from '../../components/UI/SubHead'

function Whys(props) {
  return (
    <div className={`${props.className} shrink-0 shadow-2xl px-5 py-8 border border-gray-300 h-fit lg:top-20 lg:sticky`}>
        <Heading className='lg:text-2xl' heading="Why treatat"/>
        <SubHead subhead='4000+ patients can’t go wrong!'/>
        <br/>
        <div className=' flex flex-col gap-3'>
            {
                whys.map((why, key) => (
                    <div className='flex gap-1' key={key}>
                        <span className=' text-2xl text-green-900 font-bold'>&#1421;</span>
                        <p className='text-gray-600'>
                            <span className=' font-medium'>{why.main}</span>
                            <span className=' block text-xs font-medium'>{why.sub}</span>
                        </p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Whys