import React from 'react'
import ButtonAssist from '../../../components/UI/ButtonAssist'
import Heading from '../../../components/UI/Heading'
import SubHead from '../../../components/UI/SubHead'
import WrapperCard from '../../../components/UI/WrapperCard'
import SocialCircle from '../../../components/UI/SocialCircle'
import { socialicons } from '../../../assets/constants'
function Confused() {
  return (
    <>
    <WrapperCard className='flex flex-col text-center gap-6'>
        <div>
            <Heading heading='Are you still confused?'/>
            <SubHead subhead='Get a second opinion and medical advice from our expert team.'/>
        </div>
        <ButtonAssist className='self-center' to='/help' text='Get Assistance Now'>&nbsp;&gt;</ButtonAssist>
    </WrapperCard>
    <div className='w-full bg-primarybg'>
        <WrapperCard className=' text-center'>
            <Heading heading='Your only partner for Simple, Reliable, Affordable Healthcare'className='font-normal text-[#fff] py-4'/>
        </WrapperCard>
    </div>
    <WrapperCard className='text-center'>
        <Heading heading='Get in Touch'/>
        <div className='gap-4 md:gap-10 flex items-center justify-center p-6'>
        {socialicons.map((icon, key) => (
                <SocialCircle key={key} page={icon.link} label={icon.label}>
                    <icon.icon className={icon.color}/>
                </SocialCircle>
            ))
        }
        </div>
    </WrapperCard>
    
    </>
  )
}

export default Confused