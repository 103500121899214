import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa'
import { useLocation } from 'react-router-dom';
import WrapperCard from '../../components/UI/WrapperCard';
import Whys from './Whys';

function Success() {
    const location = useLocation();
    const props= location.state;
    const [msg, setMsg] = useState('');
    const msgPM = 'Address proof (passport prefered) & Medical report';
    const msgP = 'Address proof (passport prefered)';
    const msgM = 'Medical report';
    useEffect(() => {
        if (props.flag === '00') {
          setMsg(msgPM);
        } else if (props.flag === '10') {
          setMsg(msgM);
        } else if (props.flag === '01') {
          setMsg(msgP);
        } 
    }, [props.flag]); 
  return (

    <WrapperCard className='select-none flex flex-col gap-4 lg:justify-between lg:flex-row'>
        <div className='lg:mx-0 mx-auto max-w-xl border-2 border-gray-300 shadow-xl relative overflow-hidden bg-lightbg p-6 '>
            <img src='/images/tick.png' className=' h-1/2 -right-20 -top-30 opacity-20 absolute' alt='hospital-submit-success'/>
            <h1 className=' text-lg md:text-2xl font-semibold'>Thank you, Submission Successfull</h1>
            <h4 className='text-sm'>Please ensure that you check the email sent to you by TreatAt</h4>
            {props.product !== undefined &&
            <div className='flex mt-5 gap-4 text-sm'>
                <img className='h-20 w-20' src={props.product.img} alt='best-hospital'/>
                <div>
                    <h1 className='  font-semibold'>{props.product.name}</h1>
                    <p>{props.product.location}</p>
                </div>
            </div>}
            { msg !== '' &&
            <div className='mt-5 flex flex-col md:flex-row bg-red-100 p-3 gap-2 border-2 border-red-300'>
                <FaExclamationCircle className=' text-red-500' size={20}/>
                <div>
                    <h2 className='hidden md:block font-semibold'>Important!</h2>
                    <p className=' text-xs'>
                        We noticed that you did <b>not</b> attach <b>{msg}</b>. Kindly replay to the email with 
                        correct documents so that we can help you find a hospital that suits your budget and needs.
                    </p>
                </div>
            </div>}
            <div className='sm:ml-0 grid grid-cols-2 text-xs mt-3 gap-2'>
                <div>
                    <span className=''>Name:</span><br/>
                    <span>{props.name}</span>
                </div>
                <div>
                    <span className=''>Email ID:</span><br/>
                    <span>{props.email}</span>
                </div>
                <div>
                    <span className=''>Country code:</span><br/>
                    <span>{props.cc}</span>
                </div>
                <div>
                    <span className=''>Phone:</span><br/>
                    <span>{props.mobile}</span>
                </div>
            </div>
        
            <div className='mt-6 mb-4 text-xs leading-5 text-justify'>
                <h2 className=' font-semibold mb-2'>What happens next?</h2>
                <ul className=' list-decimal ml-4 '>
                <li>
                    <div>
                    <h2 className=' font-semibold'>Confirmation</h2>
                    <p>
                        We have sent you an email to <span className='italic font-semibold'>{props.email}</span> This means that your request 
                        has been submitted to the TreatAt Assist Team. Make sure you check your email.
                    </p>
                    </div>
                </li>
                <li>
                    <div>
                    <h2 className=' font-semibold'>Assistance</h2>
                    <p>
                        A member of our assist team will get in touch with you, in your local language, 
                        to understand more about your needs and if necessary, guide you through your options.
                    </p>
                    </div>
                </li>
                <li>
                    <div>
                    <h2 className=' font-semibold'>Treatment Options</h2>
                    <p>
                        If you need a treatment plan and a cost estimate for your condition, 
                        TreatAt Assist team will also get it for you from the hospital.
                    </p>
                    </div>
                </li>
                <li>
                    <div>
                    <h2 className=' font-semibold'>Finalize</h2>
                    <p>
                        Once we have a clear idea of your requirements, TreatAt will get you the doctor's appointment and/or 
                        invitation letter within 24 hours - depending on working hours at the hospital.
                    </p>
                    </div>
                </li>
                </ul>
            </div>
            <span className=' text-xs'>For any help email us on 
                <a className=' text-red-700 font-semibold' href='mailto:care@treatat.com'> care@treatat.com </a> 
                or chat with us
            </span>
        </div>
        <Whys className='hidden lg:block'/>
    </WrapperCard>

  )
}

export default Success