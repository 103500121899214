import React from 'react'
import { MdPhone } from 'react-icons/md';
import { MdOutlineChat } from 'react-icons/md';
import { footers } from '../assets/constants'
import { Link } from 'react-router-dom';
import WrapperCard from './UI/WrapperCard';

function Navbar() {

  const call = footers[0].contact[0];

  return (
    <nav className=' z-50 shadow-md top-0 sticky bg-white py-1'>
        <WrapperCard className='flex justify-between items-center h-14 md:h-16'>
            <Link className=' h-14 md:h-16 hover:cursor-pointer' to='/'>
              <img className=' my-auto h-full p-2 w-full' src='/images/logo.png' title='logo-treatat' alt='nav_logo'/>
            </Link>
            <div className='flex gap-4 md:gap-12 items-center'>
                <a href={call.link} className='flex items-center gap-2 hover:text-green-700 cursor-pointer' aria-label={call.type}>
                    <MdPhone className='' size={30}/>
                    <div className='hidden md:block text-gray-500'>
                      <p className='  uppercase text-xs font-semibold'>Get a call back</p>
                      <span><b>+91623880208</b></span>
                    </div>
                </a>
                <Link to='/help' className=' hover:text-green-700 hover:border-green-700 hover:bg-blue-200 cursor-pointer text-orange-600 flex gap-2 p-3 md:border-2 border-orange-600 items-center' aria-label='help'>
                  <MdOutlineChat className='' size={30}/>
                  <p className='font-extrabold text-xs uppercase hidden md:block'>Need Help?</p>
                </Link>
            </div>
        </WrapperCard>
    </nav>
  )
}

export default Navbar