import React from 'react'

function PageLoader() {
  return (
    <div className='loader-container bg-white'>
      <img src='/images/logo.png' title='treatat' alt='logo' className= ' w-48 h-25 animate-bounce'/>
    </div>
  )
}

export default PageLoader