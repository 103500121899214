import React from 'react'
import Heading from '../../../components/UI/Heading'
import SubHead from '../../../components/UI/SubHead'
import ButtonAssist from '../../../components/UI/ButtonAssist'
import WrapperCard from '../../../components/UI/WrapperCard'


function CompleteCare() {
  return (
    <div className='w-full bg-lightbg'>
    <WrapperCard className='  flex justify-between items-center'>
        <div className='  flex flex-col gap-7 my-auto basis-auto lg:basis-4/5'>
            <div>
                <Heading heading='TreatAt Complete Care'/>
                <SubHead subhead='Everything you need to go for your treatment'/>
            </div>
            <div className=' text-sm flex-col sm:flex-row sm:gap-16  md:gap-32 lg:gap-10 xl:gap-24 flex gap-y-2  font-bold '>
                <div className='tick flex shrink-0 flex-col gap-2'>
                    <span>Hospital & doctor recommendations</span>
                    <span>Treatment options</span>
                    <span>Reservations & appointments</span>
                </div>
                <div className='tick flex shrink-0 flex-col gap-2'>
                    <span>Full medical assessment</span>
                    <span>Pre-book your rooms</span>
                    <span>Cost estimates</span>
                </div>
            </div>
            <ButtonAssist to='/help' className='' text='Get Assistance Now'>&nbsp;&gt;</ButtonAssist>
            <p className='uppercase text-xs'>only reputed & accredited hospitals</p>
            <div className=' h-10'>
                <img loading='lazy' title='complete care' className='h-10 w-52' src="/images/medical-Certificates-Color.png" alt='certificates'/>
            </div>
        </div>
        <div className='lg:block hidden'>
            <img loading='lazy' src='/images/best-hospitals-in-india.webp' title='best-hospitals-in-india' className='' alt='care'/>
        </div>
    </WrapperCard>
    </div>
  )
}

export default CompleteCare