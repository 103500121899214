import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa';
import { MdVerifiedUser } from 'react-icons/md';
import { Link } from 'react-router-dom';
function Banner(props) {
    const hospitals = props.allHospitals;
    const distinctStates = [...new Set(hospitals.map((h) => h.address.state))];
    const [filteredHospitals, setFilteredHospitals] = useState([])
    const [selectedState, setSelectedState] = useState('default')
    const handleSelect = (e) =>{
        setSelectedState(e.target.value);
        document.getElementById('search-box').value = '';
        setFilteredHospitals([]);
    }
    const handleSearchFilter = (e) => {
        let byStateFilter;
        const searchWord = e.target.value
        selectedState === 'default'
            ? byStateFilter = hospitals
            : byStateFilter = hospitals.filter((hospital) => {
                return hospital.address.state === selectedState;
            }) 
            
        const newFilteredData = byStateFilter.filter((hospital) =>
            hospital.name.toLowerCase().includes(searchWord.toLowerCase())
        )
        searchWord === ''
            ? setFilteredHospitals([])
            : setFilteredHospitals(newFilteredData)
    }

    return (
        <>
        <div className="m:px-8 flex flex-col h-64 md:h-[25rem] lg:h-[35rem] text-white bg-bannerbg bg-cover bg-no-repeat shadow-xl">
            <div className='my-auto flex flex-col items-center gap-6'>
                <div className='text-center sm:text-xl md:text-2xl lg:text-4xl font-bold '>
                    <h1 className=''>Find the perfect hospital for your treatment</h1>
                </div>
                <form onSubmit={e => e.preventDefault()} className=' text-black flex gap-0.5 items-center w-10/12 sm:w-3/5 lg:w-2/4 xl:w-2/5 bg-gray-50 p-1 md:p-2 rounded-sm bg-opacity-20'>
                    <div className='hidden font-semibold  md:block'>
                        <select 
                            className=' cursor-pointer text-center h-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                            value={selectedState}
                            onChange={handleSelect}
                        >
                            <option value='default'>All States</option>
                            {distinctStates.map((state, key) => (
                                <option key={key} value={state}>{state}</option>
                            ))}
                        </select>
                    </div>
                    <div className=' flex relative w-full'>
                        <input 
                            id='search-box'
                            type='text'
                            className=' border-none focus:outline-none h-10 w-full pl-4'
                            placeholder='Search best hospitals and doctors'
                            onChange={handleSearchFilter}
                        />
                        {filteredHospitals.length !== 0 && 
                        <div className=' z-10 border-2 border-gray-200 grid w-full h-auto top-10 bg-white absolute max-h-48 overflow-scroll hide-scroll'>
                            {filteredHospitals.map((hospital) => (
                                <Link 
                                    tabIndex={0}
                                    onSelect={(e)=> e.target.style.color='red'}
                                    className='hover:text-white text-sm hover:bg-blue-500 cursor-pointer p-3'
                                    to={
                                        {pathname:  `/${hospital.name.replace(/ +/g, "")}` }
                                    } 
                                    state={
                                        {props: hospital}
                                    }
                                    key={hospital._id}
                                >
                                        {hospital.name}
                                </Link>
                            ))}
                        </div>}
                        <div className=' bg-white h-10 text-xl text-gray-500'>
                            <FaSearch className='mt-2 mr-1'/>
                        </div>
                    </div>
                </form>
                <div className=' md:flex text-xs gap-6 hidden items-center'>
                    <span className=' font-medium text-sm'>Popular searches:</span>
                    {/* Reading from hospitals popular 'true' */}
                    {hospitals.filter((hospital) => {return hospital.popular === 'true'}) 
                        .map((hosp, key) => (
                            <Link 
                                className='' 
                                
                                to={
                                    {pathname:  `/${hosp.publiclink}` }
                                } 
                                state={
                                    {props: hosp}
                                }
                                key={hosp._id}

                            >
                                {hosp.name.replace(/Hospitals|Hospital|hospital|hospitals/g, '')}
                            </Link>
                        ))
                    }
                </div>
            </div>
            
            <div className='mx-auto mb-4 flex items-center gap-1'>
                <MdVerifiedUser className='text-green-500 text-2xl md:text-4xl'/>
                <div className=' font-medium md:uppercase flex gap-1  md:flex-col text-xs '>
                    <span className='text-center'>All Hospitals are</span>
                    <span>Trusted & Verified</span>
                </div>
            </div>
        </div>
        {/* <div className=' font-semibold text-white  lg:justify-center text-center items-center p-3 lg:flex gap-8 bg-blue-900'>
            <p className='lg:mb-0 mb-3'>Get reliable second opinions from our doctors with 15+ years of experience across specialties</p>
            <a className='rounded-md uppercase p-2 bg-red-900 text-sm hover:bg-[green]' href='#'>Doctor&nbsp;&nbsp;Consultation</a>
        </div> */}
        </>
    )
}

export default Banner