import React from 'react'
import WrapperCard from './UI/WrapperCard'
import { footers, socialicons } from '../assets/constants'
import { FooterSocial } from './UI/SocialCircle'
import { Link } from 'react-router-dom'
import Line from './../pages/components/Line';

function Footer() {
  return (
    <>
      <Line/>
      <div className='bg-bgfooter'>
      <WrapperCard className=' text-textfooter'>
        <div className='mb-8 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-6'>
          <div className='text-sm flex justify-start flex-col gap-4'>
            <img className=' left-0 -ml-8 -mt-6 w-52 h-25' title='treatat' src='/images/footerlogo.png' alt='footerlogo'/> 
            <span>World’s finest healthcare without boundaries</span>
            <div className='flex gap-2'>
              {/* Social media icons from constants.js */}
              {socialicons.map((icon, key) => (
                  <FooterSocial key={key} page={icon.link} label={icon.label}>
                      <icon.icon size={15} className={icon.color}/>
                  </FooterSocial>
                ))
              }
            </div>
          </div>
          <div className=' lg:ml-6 flex-col gap-3 flex '>
            <span className='text-white'>Contact</span>
            <div className=' text-sm font-light grid gap-2'>
              {/* Footer contact details from constants.js */}
              {footers[0].contact.map((contact, key) => (
                    <a href={contact.link} target='blank' key={key} className=' hover:underline flex gap-1 items-center' aria-label={contact.type}>
                      <contact.icon/>
                      <span>{contact.type}</span>
                      <span className=' text-yellow-400' >{contact.data}</span>
                    </a>
                  ))
              }  
            </div>
          </div>
          <div className=' lg:ml-6 flex-col gap-3 flex'>
            <span className='text-white'>More</span>
            <div className='text-sm font-light grid gap-2'>
              {/* Footer more links from constant.js */}
              {footers[0].more
                .map((topage, key) => (
                  <Link className='hover:underline'key={key} to={topage.link}>
                    {topage.to}
                  </Link>
                ))
              }
            </div>
          </div>
          <div className=' lg:ml-4 flex-col gap-3 flex'>
            <span className='text-white'>For Patients</span>
            <div className='text-sm font-light grid gap-2'>
              {/* Footer more links from constant.js */}
              {footers[0].forpatients
                .map((topage, key) => (
                  <Link className='hover:underline'key={key} to={topage.link}>
                    {topage.to}
                  </Link>
                ))
              }
            </div>
          </div>
        </div>
        <span className='text-xs'>Copyright © 2023 Meditreat Services Pvt. Ltd. All rights reserved.</span>
      </WrapperCard>
      </div>
    </>
  )
}

export default Footer