import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';

axios.defaults.baseURL = 'https://api.treatat.com/api/';
//axios.defaults.baseURL = 'https://taapi-indol.vercel.app/api/';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
);
