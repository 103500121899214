
import { FiSend } from 'react-icons/fi';
import { BsCardChecklist } from 'react-icons/bs';
import { GiHospitalCross } from 'react-icons/gi';
import { AiOutlineFileDone } from 'react-icons/ai';
import { FaMediumM, FaWhatsapp, FaLinkedinIn, FaInstagram, FaFacebookF, FaTwitter } from 'react-icons/fa';
import { MdPhone, MdEmail, MdPhoneCallback} from 'react-icons/md';

export const socialicons = [
    {
        'icon': FaTwitter,
        'link': 'https://twitter.com/treat_at',
        'color': 'text-blue-500',
        'label': 'twitter'
    },
    {
        'icon': FaFacebookF,
        'link': 'https://www.facebook.com/TeamTreatAt',
        'color': 'text-blue-900',
        'label': 'facebook'
    },
    {
        'icon': FaInstagram,
        'link': 'https://www.instagram.com/treatatindia/',
        'color': 'text-yellow-900',
        'label': 'instagram'
    },
    {
        'icon': FaLinkedinIn,
        'link': 'https://www.linkedin.com/company/treatat',
        'color': 'text-blue-700',
        'label': 'linkedin'
    },
    {
        'icon': FaWhatsapp,
        'link': 'https://api.whatsapp.com/send?phone=+916238880208&text=Hello%20TreatAt',
        'color': 'text-green-600',
        'label': 'whatsapp'
    },
    {
        'icon': FaMediumM,
        'link': 'https://medium.com/@treatat',
        'color': 'text-black',
        'label': 'medium'
    }
    
]


export const faqs = [

    {
      id: 1,
      question: "Why should I Trust Treat@?",
      answer:`Here at Treat@, we have established an association with the most trusted Hospitals and Doctors, where in we maintain the 
            quality of our services throughout the journey of the patients and the patient’s families. We act as a bridge among the different 
            channels, so that the right treatment is reached to the user of our services. Thus we have proudly served over thousands of patients coming to India for medical treatment.`
    },

    {
      id: 2,
      question: "What are the service charges if I opt for Treat@?",
      answer:`Here at Treat@, we try to provide the best services at a convenient charge that equally serves the best practices in this industry as well as quality 
            of the services are maintained. We customize the services as per the requirements of the patients and our charges are entirely 
            dependent on the service option selected by the patients and as conveyed we try to keep our charges at the minimum range. `
    },

    {
      id: 3,
      question: "What is the procedure of booking for a treatment?",
      answer:`Here at Treat@, we have kept the booking system simpler. The patients may choose from the list of the hospitals and a few medical documents will have to be 
            submitted. On the basis of this information, we will consult the concerned doctor and the hospital and will send an estimate of the treatment. Once this is agreed 
            upon by the patients, they can confirm the booking with us.`
    },

    { 
      id: 4,
      question: "Can we be provided with the estimate of the treatment cost?",
      answer:`Here at Treat@, we consult the concerned hospital and get the estimated cost for the treatment for the patients. 
            Therefore, the things to note on the part of the patients would be the following: 
            •	The estimate will be based on the medical condition of the patient and the type of the hospital rooms or cabins are selected for the duration of the treatment.
            •	All the medical history of the patients will be thoroughly analyzed by the team of specialists at the hospital and the treatment packages may be suggested on the basis of that.`
    },

    { 
      id: 5,
      question: "What are the inclusions of the estimate?",
      answer:`The details provided by the hospitals will be as followed: 
            •	Surgeon and support medical staff’s fees
            •	Charges for the surgery (when required) and the OT
            •	Charged for doctor’s consultation pre/post surgery or as required
            •	Cabin for stay and beds
            •	Nursing charges
            •	Patient Dietary charges
            •	Statures, tubes, cotton charges.
            `
    },
    { 
      id: 6,
      question: "Is the health insurance accepted in India?",
      answer:`If the health insurance is valid in other countries or in India, then the patients may inform the insurance department 
            as well as the concerned hospital previous to the treatment. In that case, the hospital may ask for a payment guarantee from the 
            patient party, which the patients have to arrange from their home country.`
    },
    { 
      id: 7,
      question: "How much time will be required to admit to the hospital for a treatment?",
      answer:`Here at Treat@, we ensure quick delivery of the services and less waiting time. After the initial diagnosis, the treatment and 
            the surgery date is scheduled as quick as possible. Treat@ values the precious time of the patients who are expecting fair treatment of their 
            ailments and we completely empathize with them. Hence, we discard queuing up and waiting time of the medical services.`
    },
    { 
      id: 8,
      question: "Will there be any assistance regarding the Medical Visa?",
      answer:`Here at Treat@, we will consult the concerned hospital prior to the arrival of the patients, and arrange the invitation letter to be produced 
            before High Commission of India for getting a medical visa done. `
    },
    { 
      id: 9,
      question: "Is it possible for the family members to visit along with the patients, who are attending the patients using the medical visa?",
      answer:`The attendants will be consisting of the blood relation of the patients only. A separate Medical Attendant Visa (MEDX) has to be applied 
            by the patients’ attendants which can be a maximum of two in number. Treat@ can arrange a letter from hospital in this case as well.`
    },
    { 
      id: 10,
      question: "What is the validity of the Medical Visa?",
      answer:`The initial duration of visit to India can range from the period of the treatment upto One Year, whichever is less. A maximum of three 
            repeated entries will be allowed per patient when it is necessary.`
    },
    { 
      id: 11,
      question: "What should be the necessary medical documents or documents in general, those have to be carried during the visit?",
      answer:`The following medical documents will have to be carried by the patients in addition to the specific documents applied: 
         •	Medical Records, Test Reports, Doctor’s references
        •	Residential Proofs, Driving License, Passport Copies
        •	Passport Size photos – 20 copies
        •	Bank Statements, Medical Insurance Details
        Apart from these, the patients are advised to carry all the personal and medical history details that they may find necessary to 
        produce before the medical consultants in India.`
    },
    { 
      id: 12,
      question: "If the patients do not have any medical records, what is the pre-booking documentation process?",
      answer:`If any patient does not have the record, then he/she must share the symptoms, prescription from the local doctor and previous 
            medical history for us to arrange. According to the information provided, we can take the procedure further.`
    },
    { 
      id: 13,
      question: "What are the necessary Vaccines or Tests required for visiting India?",
      answer:`The patients and patients’ families are advised to check with the Indian Embassy, Government Advisory and the doctor consultation 
            in their home country for better understanding of the necessary vaccines to be taken. Hence the following can be considered as hands on guide:
            •	COVID Test
            •	Oral Polio Vaccination
            •	Hepatitis A
            •	Hepatitis B(In case of stays exceeding 60 days)
            •	Measles, Mumps and Rubella (MMR)
            •	Typhoid
            •	Hemophilus b conjugate vaccine (Hib)
            •	Diptheria Pertussis and Tetanus (DPT)
            •	Japanese B encephalitis (JBE)
            `
    },
    { 
      id: 14,
      question: "What facilities can be provided for airport transfers?",
      answer:`Here at Treat@, we can provide the pickup and drop facility to 
            and fro airport against the clear communication of the journey schedule. Airport transfers can be complimentary sometimes depending on the case. And in case when the pickup and drop is chargeable, it will be informed to the patients prior to the service.`
    },
    { 
      id: 15,
      question: "How the doctor’s appointment will be scheduled?",
      answer:`After receiving the requisition from the patients, Treat@ will co ordinate with the international patient’s desk in the 
            concerned hospital and will fix an appointment on priority basis for the checkup and medication. We will cater to the services as expected by the patients.`
    },
    { 
      id: 16,
      question: "What will be the availability status for the pharmacies in the Hospitals?",
      answer:`Most of the hospitals generally have their own pharmacies that are easily accessible to the patients, so that the patients do not have to worry about the 
            availability of the medicines or any other necessities for the treatment of the patients.`
    },
    { 
      id: 17,
      question: "What are the modes of Payment?",
      answer:`At Treat@, we receive the payment through card payment and online transfer.`
    },
    { 
      id: 18,
      question: "What is the trust ratio for the quality of the treatment in India?",
      answer:`India’s hospitals are of global standards and our skilled specialists have given a sizeable boost to our country’s credibility as a preferred destination for 
            patients from outside India. The medical expertise available in India and the resultant medical outcomes are talked about all over the world and consistently meet 
            international benchmarks and standards. Some significant causes include lower costs of medical procedures, world class expertise and treatment solutions, highly 
            qualified doctors. It is also worth mentioning that an additional important criterion for servicing international patients is international accreditation from the Joint 
            Commission International (JCI). Their standards must be met by hospitals in order to be accredited and the JCI serves to provide international patients with assurance and confidence in selecting hospitals for treatment.`
    },
    { 
      id: 19,
      question: "What measures do Treat@ take to secure the medical information for the patients?",
      answer:`Here at Treat@, we can guarantee the safety of the medical information of the patients. We cater to the most sensitive service of our clients that is the healthcare service. Hence we always strive to secure the 
            necessity of the patients who are trusting Treat@ for their medical treatment out of their own country. All the information is kept in our custody with utmost confidentiality.`
    },
    { 
      id: 20,
      question: "Will the patients receive an updated medical record from the hospital post treatment?",
      answer:`Once the treatment is complete, an updated medical file along with all the details and advisory will be shared with the patients before they leave the country. The complete guideline will be received by the 
            patients, so that they do not face any further disturbance or hazards related to the post care.`
    },

  ]



export const hows = [
    {
        'step': '1. Submit your request',
        'icon': FiSend,
        'detail': 'Let us know what treatment you require and upload the relevant reports for us to assist you better.'
         
    },
    {
        'step': '2. Get Medical Guidance',
        'icon': BsCardChecklist,
        'detail': 'Ask for guidance from our team of specialist doctors in choosing your treatment and hospital'

    },
    {
        'step': '3. Choose Hospital',
        'icon': GiHospitalCross,
        'detail': 'Choose a hospital that suits your needs the best. Get your treatment plan and cost estimate from them.'

    },
    {
        'step': '4. Make Reservations',
        'icon': AiOutlineFileDone,
        'detail': 'Confirm your treatment plan and make all the necessary bookings before going to the hospital.'

    }
]

export const whys = [
    {
        'main': 'Accredited Hospitals Only',
        'sub': 'JCI, NABL, NABH'
    },
    {
        'main': 'Guaranteed Response',
        'sub': 'Typically Responds In 4Hrs'
    },
    {
        'main': 'Faster Process',
        'sub': 'For TreatAt Patients'
    },
    {
        'main': 'Dedicated Patient Manager',
        'sub': 'End to End support'
    },
    {
        'main': '20% Cost Savings',
        'sub': 'Highest quality care and results'
    }
]

export const reviews = [
    {
        'name': 'Rajib Baidya',
        'rating': '★★★★★',
        'from': 'from India',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FRajib%20baidya.png?alt=media&token=7da5b90b-d897-482a-b7b6-3fb011562e82',
        'content': 'The Team TreatAt is very friendly to all my queries and their reply according to all my question are very frequent that I get very pleased. Thanks everybody and I wish all the best to Team TreatAt.'
    },
    {
        'name': 'Tanver Hossain',
        'rating': '★★★★★',
        'from': 'from Bangladesh',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FTanvir%20Hossain.png?alt=media&token=230a2203-7527-4b7c-bf7f-1afdb657f53b',
        'content': `Best service provided by them specially Mrs. Moushumi Mahesh. She did excellent job. I wish great success of her as well as the TreatAt.`
    },
    {
        'name': 'Nil Sarkar',
        'rating': '★★★★★',
        'from': 'from Bangladesh',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FNil%20Sarkar.png?alt=media&token=35f39abd-d1fa-4065-a179-a8a067aaf4c0',
        'content': `(Translated by Google) Very good service. I am fully satisfied with your service. Thank you so much seasonally for providing such wonderful service.
                    (Original)
                    খুব ভালো পরিষেবা। আমি পুরোপুরি সন্তুষ্ট আপানাদের পরিষেবায়। মৌসুমি আপনাকে অনেক অনেক ধন্যবাদ এত সুন্দর পরিষেবা দেয়ার জন্য।`
    },
    {
        'name': 'Ts t',
        'rating': '★★★★★',
        'from' : 'from India',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FTs%20t.png?alt=media&token=b887251d-6af5-4859-8d65-8c6519771444',
        'content': 'TreatAt service is very quick.They are understand patient needs and they are very helpful. Specially Moushumi she was guide us right path.'
    },
    {
        'name': 'Mita begum',
        'rating': '★★★★★',
        'from': 'from Bangladesh',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FMita%20Begum.png?alt=media&token=cefaaf05-d8b9-486e-b0d5-d4cb364e5bb6',
        'content': `(Translated by Google) Thank you very much for the seasonal Apu I love your processing and usage
                    (Original)
                    আপনাদে প্রসেসিং ও ব্যাবহার আমার কাছে অনেক ভাল লেগেছে  মৌসুমি আপুকে অনেক ধন্যবাদ`
    },
    {
        'name': 'Rina Nasrin',
        'rating': '★★★★★',
        'from': 'from India',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FRina%20nasrin.png?alt=media&token=a0d9b259-762b-4593-8835-e2f31bdc60eb',
        'content': 'Quick and friendly service provider.'
    },
    {
        'name': 'Juher Choudhury',
        'rating': '★★★★★',
        'from': 'from Bangladesh',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FJuher%20Chaudri.png?alt=media&token=af8328cb-1382-4f0b-a2bb-aaec74bcfa3c',
        'content': 'Thank you TreatAt Team I get doctors Appointment easily by TreatAt # Good service. # Good Commitment Helpfully Team'
    },
    {
        'name': 'Mariyamath Ramseena',
        'rating': '★★★★★',
        'from': 'from India',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FMaryamath%20Ramseena.png?alt=media&token=8667b180-8293-4207-a4ec-8ddf26787e68',
        'content': 'Thanks a lot for your assistance - I was very impressed with your site and shall recommend it to others........'
    },
    {
        'name': 'Golam Moula',
        'rating': '★★★★★',
        'from': 'from Bangladesh',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FGulam%20moula.png?alt=media&token=ec3eb3c9-3c58-48eb-8b83-9f9449408b31',
        'content': 'Your Service is excellent. Carry on Pl.'
    },
    {
        'name': 'Misbah Uddin',
        'rating': '★★★★★',
        'from': 'from India',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FMisbah%20Uddin.png?alt=media&token=be481867-37c1-4fc0-a0bc-2d72f84b9b70',
        'content': 'Excellent Service provider. Trustable services.'
    },
    
    {
        'name': 'Priyo Mon',
        'rating': '★★★★★',
        'from': 'from India',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FPriyo%20mon.png?alt=media&token=87d5af27-f84e-404f-8372-be1c31043343',
        'content': 'I got excellent service from Mousumi. She is very punctual and helpful.'
    },
    {
        'name': 'MESBAH UDDIN FOYSAL',
        'rating': '★★★★★',
        'from': 'from India',
        'img': 'https://firebasestorage.googleapis.com/v0/b/treatat-project-1721e.appspot.com/o/reviews%2FMisbah%20Uddin%20Foysal.png?alt=media&token=72b87869-3378-49cc-a1de-15cb1b236d70',
        'content': `Great and Quick service.`
    },

]

export const says = [
    {
        'name': 'Abdullah Saleh Nasser Al Thahri',
        'visit': 'Visited in January, 2018',
        'from': 'From Saudi Arabia',
        'say': `I found out about TreatAt online as I was looking for management of my post stroke conditions. 
                I needed advice on my future lifestyle choices and medical management. TreatAt helped me get connected to one 
                of the best doctor for my treatment. Their medical team was pleasant and supportive. Really happy with the services`
    },
    {
        'name': 'Kalumba John',
        'visit': 'Visited in January, 2018',
        'from': 'Uganda',
        'say': `Truly reliable medical support from TreatAt. They have all the things you need for treatment.`
    },
    {
        'name': 'Mohammed Iqbal Hossain',
        'visit': 'Visited in Feb, 2018',
        'from': 'From Bangladesh',
        'say': `looking for management of my post stroke conditions. 
                I needed advice on my future lifestyle choices and medical management. TreatAt helped me get connected to one 
                of the best doctor for my treatment. Their medical team was pleasant and supportive. Really happy with the services`
    },
    {
        'name': 'Ben Twine',
        'visit': 'Visited in April, 2018',
        'from': 'From Uganda',
        'say': `I found out about TreatAt online as I was looking for management of my post stroke conditions. 
                I lifestyle choices and medical management. TreatAt helped me get connected to one 
                of the best doctor for my treatment. Their medical team was pleasant and supportive. Really happy with the services`
    },
    {
        'name': 'Gerald Adinde',
        'visit': 'Visited in March, 2018',
        'from': 'From Nigeria',
        'say': `I found out about s I was looking for management of my post stroke conditions. 
                I lifestyle choices and medical management. TreatAt helped me get connected to one 
                of the best doctor for my treatment. Their medical team was pleasant and supportive. Really happy with the services`
    },
    {
        'name': 'Shameem',
        'visit': 'Visited in jan, 2017',
        'from': 'From Bangladesh',
        'say': `g for management of my post stroke conditions. 
                I lifestyle choices and medical management. TreatAt helped me get connected to one 
                of the best doctor for my treatment. Their medical team was pleasant and supportive. Really happy with the services`
    }

]


export const footers = [
    {
        'contact': [
            {
                'type': 'Phone:',
                'data': '(+91) 623 886 1334',
                'link': 'tel:+91 623 886 1334',
                'icon': MdPhone
            },
            {
                'type': 'Email:',
                'data': ' care@treatat.com',
                'link': 'mailto:care@treatat.com',
                'icon': MdEmail
            },
            {
                'type': 'Chat:',
                'data': '(+91) 623 888 0208',
                'link': 'https://api.whatsapp.com/send?phone=+916238880208&text=Hello%20TreatAt',
                'icon': FaWhatsapp
            },
            {
                'type': 'Get a call back',
                'data': '',
                'link': 'https://treatat.com/contact',
                'icon': MdPhoneCallback
            }
            
        ],

        'more': [
            {
                'to' : 'About',
                'link': '/about'
            },
            {
                'to' : 'Contact',
                'link': '/contact'
            },
            {
                'to' : 'Disclaimer',
                'link': '/disclaimer'
            },
            {
                'to' : 'Terms & Conditions',
                'link': '/tc'
            },
            {
                'to' : 'Privacy & Statement',
                'link': '/privacy-policy'
            }
        ],

        'forpatients': [
            {
                'to' : 'TreatAt Assist',
                'link': '/help'
            },
            {
                'to' : 'Steps For Medical Treatment in India',
                'link': '/steps-for-medical-treatment-in-india'
            },
            {
                'to' : 'Medical Visa',
                'link': '/visa-process'
            },
            {
                'to' : 'FAQs',
                'link': '/faqs'
            },
            {
                'to' : 'Refund policy',
                'link': '/refund'
            }
        ]

    }
]
