import axios from 'axios';

export const getAllHospitals = async () => {
    try{
      const res = await axios.get('/hospital/all');
      return res.data
    }catch(err){
      //console.log(err);
      alert("Network error while loading all hospitals")
      return err;
    }
  }

export const getOneHospital = async (pathId) => {
    try{
        const res = await axios.get('hospital/'+ pathId);
        return res.data;
    }catch(err){
        return err.response.data;
    }
}

// ------- Submit form , registering, contactus, help to zoho desk-----------
export const doRegister = async(data) => {
    //console.log(data)
    try {
      return await axios.post('zoho/register', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
          }
      });
    } catch (err) {
        // console.log(err)
        // alert("Network error, please try again")
        return(err)
    }
}


export const verifyemail = async(data) => {
  // console.log(data)
  try {
    const response = await axios.post('/hospital/verifyemail/', {email: data});
    // console.log(response)
    return response.data
  } catch (err) {
      alert('Network error while verifying email')
      // console.log(err)
      return 'error'
  }
}


export const getCountry = async() => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    return data.country_name;
  } catch (error) {
    return undefined;
  }
}
