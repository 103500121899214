import React from 'react'
import { Banner, CompleteCare, Achieved, How, Testimonial, Why, Confused, TopHospitals } from './index'
import { useState, useEffect } from 'react';
import { getAllHospitals } from '../../apiCalls/hospitalCalls';
import { Helmet } from 'react-helmet-async';


function Home() {

  const [hospitals, setHospitals] = useState([]);

  useEffect(() => {
    const getHospitals = async () => {
      const response = await getAllHospitals();
      setHospitals(response);
    }
    getHospitals();
  },[])

  return (
    <>
      <Helmet>
        <title>Best Hospitals & Medical Treatments in India - TreatAt</title>
        <meta 
          name='description' 
          content='TreatAt offer you the online booking for medical facilities 
          quite in advance to avoid any inconveniences during your medical treatment in India.'
        />
        <link rel="canonical" href="https://www.treatat.com/" />
      </Helmet>
      <Banner allHospitals={hospitals}/>
      <TopHospitals allHospitals={hospitals}/>
      <CompleteCare/>
      <How/>
      <Why/>
      <Testimonial/>
      <Achieved/>
      <Confused/>
    </>
  )
}

export default Home