import { useState } from 'react';
import Heading from '../../../components/UI/Heading';
import SubHead from '../../../components/UI/SubHead';
import WrapperCard from '../../../components/UI/WrapperCard';
import { reviews } from '../../../assets/constants';
import { FaArrowCircleRight, FaArrowCircleLeft } from 'react-icons/fa';

function Testimonial() {

    const[isDown, setDown] = useState(false);
    const[startX, setStartX] = useState(0);
    const[scrollLeft, setScrollLeft] = useState(0);
    const ele = document.querySelector('#slider');
    const end = (e) => {
        setDown((prev) => false)
        e.target.classList.remove('cursor-grabbing')
        e.target.classList.add('cursor-grab')
    } 
    
    const start = (e) => {
        setDown((prev) => true)
        setStartX((prev) => (e.pageX - e.target.offsetLeft))
        setScrollLeft((prev) => e.target.scrollLeft)
        e.target.classList.add('cursor-grabbing')
        e.target.classList.remove('cursor-grab')
    }
    
    const move = (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - e.target.offsetLeft;
        const dist = (x - startX);
        e.target.scrollLeft = scrollLeft - dist;
    }

    const arrowRight = () => {
        ele.scrollLeft = scrollLeft - 300;
        setScrollLeft(ele.scrollLeft);
    }

    const arrowLeft = () => {
        ele.scrollLeft = scrollLeft + 300;
        setScrollLeft(ele.scrollLeft);
    }
   

    return (
        <div className='relative bg-lightbg'>
        <WrapperCard className=' text-center'>
            <Heading heading='Your most trusted partner in healthcare'/>
            <SubHead subhead='Simple . Reliable . Affordable'/>
            <div 
                onMouseEnter={(e)=>{e.target.classList.add('cursor-grab')}} 
                onMouseDown={start} 
                onMouseMove={move} 
                onMouseLeave={end} 
                onMouseUp={end} 
                className=' hide-scroll overflow-scroll grid grid-flow-col gap-4 py-6'
                id='slider'
            >
                <div className=' col-start-2 sm:col-start-1 pointer-events-none relative slide-0 font-bold text-lg text-white h-72 w-80 bg-primarybg'>
                    <div className='p-4 w-max text-left absolute bottom-0'>
                        <p className='uppercase'>Helped Over</p>
                        <h2 className=' text-5xl'>4,000</h2>
                        <span>patients go for the</span><br/>
                        <span>right treatment</span>
                    </div>
                </div>
                {reviews.map((review, key) => (
                <div key={key} className={`slide-${key+1} pointer-events-none flex-shrink-0 flex flex-col text-gray-600 h-72 w-80 `}>
                    <div className='p-2 flex h-1/3 gap-2'>
                        <img loading='lazy' title='profile' className='skeleton border-white border h-16 w-16 rounded-full' src={review.img} alt='profile' />
                        <div className=' text-left '>
                            <h2 className=' font-bold text-gray-700'>{review.name}</h2>
                            <span className=' text-sm text-yellow-400'>{review.rating}</span><br/>
                            <span className=' text-sm '>{review.from}</span>
                        </div>
                    </div>
                    <div className='p-2'>
                        <p className=' text-sm text-justify text-gray-700'>{review.content}</p>
                    </div>
                </div>
                ))}
            </div>
        </WrapperCard>
        <FaArrowCircleRight className=' text-gray-400 cursor-pointer md:top-1/2 text-3xl bottom-3 right-5 md:block hidden absolute' onClick={arrowLeft}/>
        <FaArrowCircleLeft className=' text-gray-400 cursor-pointer md:top-1/2 text-3xl bottom-3 left-5 md:block hidden absolute' onClick={arrowRight}/>
        </div>
    )
}

export default Testimonial