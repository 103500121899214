import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Routes, Route, useLocation } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import PageLoader from './components/Loading/PageLoader';
import Home from './pages/home/Home';
import Success from './pages/components/Success';

const Hospital = lazy(() => import('./pages/hospital/Hospital'));
const AllHospitals = lazy(() => import('./pages/allHospitals/AllHospitals'));
const Help = lazy(() => import('./pages/help/Help'));
const ContactUs = lazy(() => import('./pages/contactUs/ContactUs'));
const TreatmentIndia = lazy(() => import('./pages/treatmentIndia/TreatmentIndia'));
const Visa = lazy(() => import('./pages/visa/Visa'));
const Faq = lazy(() => import('./pages/faq/Faq'));
const About = lazy(() => import('./pages/about/About'));
const Terms = lazy(() => import('./pages/terms/Terms'));
const Disclaimer = lazy(() => import('./pages/disclaimer/Disclaimer'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy.jsx/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('./pages/RefundPolicy.jsx/RefundPolicy'));

function App() {

  const routePath = useLocation();
  // --------Scroll to top after each routing----------
  useEffect(() => {
    const onTop = () => {
      if(routePath.hash === '')
        window.scrollTo(0, 0);
    }
    onTop()
  }, [routePath]);


  return (
    <>
      <Navbar/>
      <Suspense fallback={<PageLoader/>}>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/:id" element={<Hospital/>}/>
          <Route path="/all-hospitals" element={<AllHospitals/>}/>
          <Route path="/help" element={<Help/>}/>
          <Route path="/contact" element={<ContactUs/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/tc" element={<Terms/>}/>
          <Route path="/disclaimer" element={<Disclaimer/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/refund" element={<RefundPolicy/>}/>
          <Route path="/faqs" element={<Faq/>}/>
          <Route path="/visa-process" element={<Visa/>}/>
          <Route path="/steps-for-medical-treatment-in-india" element={<TreatmentIndia/>}/>
          <Route path="/success/:id" element={<Success/>}/>
          <Route path="*" element={<Help/>}/>
        </Routes> 
      </Suspense>
      <Footer/>
    </>
  )
}

export default App